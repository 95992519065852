import React from "react"
import styled from "styled-components"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/Header"
import {
  Section,
  Container,
  BreadCrumb,
  SectionPageTitle,
  MarkdownContent,
  WriterWrap,
  Avatar,
  Writer,
  SocialList,
  SocialIcon,
} from "../components/SectionTags"
// import DotIcon from "../components/Icons/DotIcon"
import FaceBook from "../components/Icons/FaceBook"
import Twitter from "../components/Icons/Twitter"
import Linkedin from "../components/Icons/Linkedin"
import EmergencyEstimateCTA from "../components/CallToAction/EmergencyEstimateCTA"
import BreakpointUp from "../components/Media/BreakpointUp"

const Sections = styled(Section)`
  &:before{
    bottom:0;
    height: 91%;
  }
`
const HeroSection = styled.div`
  .gatsby-image-wrapper{
    width: 100%;
    border-radius: 8px;
    margin-bottom: 30px;
  }
`
const HeroInfo = styled.div`  
  border-bottom: 1px solid #dedede;
  margin-bottom:30px;
  padding-bottom:15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction:column;
  ${BreakpointUp.sm`
    flex-direction:row;
  `}
  ${BreakpointUp.lg`
    margin-bottom:40px;
  `}
  & .gatsby-image-wrapper {
    margin: 0;
    width: 48px;
    height: 48px;
    border-radius: 50px;
    vertical-align: middle;
  }
`

class BlogTemplate extends React.Component {
  render() {
    const contentData = this.props.data.contentfulArticle
    return (
      <Layout>
        <Seo title={contentData.metaTitle}
          description={contentData.metaDescription.metaDescription} />
        <div className="sticky-wrapper sticky">
          <Header />
        </div>
        <Sections xpt="120px" xpb="60px" mpt="120px" mpb="60px" pt="90px" pb="30px" 
        bgBeforeWidth="100%"
        bgBefore="linear-gradient(to bottom,#f5f5f5 0%, #ffffff 100%)">
          <Container maxWidth="890px">
            <BreadCrumb>
              <Link to="/"><span>Home</span></Link>
              <Link to="/articles"><span>Articles </span></Link>
              {contentData.title}
            </BreadCrumb>
            <SectionPageTitle textAlign="center">{contentData.title}</SectionPageTitle>
            <HeroSection>
              <GatsbyImage image={getImage(contentData.thumbnail)} alt={contentData.title} />
              <HeroInfo>
                <WriterWrap mt="0" mb="15px">
                  <Avatar>
                    <GatsbyImage
                      image={getImage(contentData.authorImage)}
                      alt="AES"
                    />
                  </Avatar>
                  <Writer>
                    <span className="author">{contentData.authorName}</span>
                    <span className="role text-muted">
                      {contentData.authorRole} 
                      {/* <DotIcon fill="#999" />{" "}
                      {contentData.updatedAt} */}
                    </span>
                  </Writer>
                </WriterWrap>
                <SocialList>
                  <SocialIcon>
                    <a
                      href="https://www.facebook.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaceBook />
                    </a>
                  </SocialIcon>
                  <SocialIcon>
                    <a
                      href="https://twitter.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Twitter />
                    </a>
                  </SocialIcon>
                  <SocialIcon>
                    <a
                      href="http://linkedin.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Linkedin />
                    </a>
                  </SocialIcon>
                </SocialList>
              </HeroInfo>
              <MarkdownContent
                dangerouslySetInnerHTML={{
                  __html: contentData.articleContent.childMarkdownRemark.html,
                }}
              />
            </HeroSection>
          </Container>
        </Sections>
        <EmergencyEstimateCTA
          leftData={contentData.emergencyCta}
          rightData={contentData.estimateCta}
        />
      </Layout>
    )
  }
}

export default BlogTemplate

export const pageQuery = graphql`
  query blogQuery($id: String!) {
    contentfulArticle(id: { eq: $id }) {
      metaTitle
      metaDescription {
        metaDescription
      }
      title
      thumbnail {
        gatsbyImageData(quality: 100)
      }
      authorName
      authorRole
      authorImage {
        gatsbyImageData(quality: 100)
      }
      category {
        categoryName
        service {
          serviceName
        }
      }
      articleContent {
        childMarkdownRemark {
          html
        }
      }
      emergencyCta {
        title
        linkText
        image {
          gatsbyImageData(quality: 100)
        }
      }
      estimateCta {
        title
        linkText
        image {
          gatsbyImageData(quality: 100)
        }
      }
    }
  }
`
